<template>
  <div class="chatgpt">
    <Pane />

    <a-card class="container">
      <div class="list">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          :class="item.role"
        >
          <img v-if="item.avatar" class="avatar" :src="item.avatar" />
          <default-avatar :size="32" :name="item.name" v-else />
          <div
            class="content"
            v-html="
              item.content ? renderContent(item.content) : '<p>正在处理...</p>'
            "
          ></div>
        </div>
      </div>

      <div class="footer">
        <a-textarea
          placeholder="请输入问题"
          v-model="content"
          :auto-size="{ minRows: 6, maxRows: 6 }"
          @pressEnter="send"
        />
        <a-button
          class="send"
          type="primary"
          @click="send"
          :loading="sendLoading"
          >发送</a-button
        >
      </div>
    </a-card>

    <a-modal
      :visible="visible"
      title="新版来袭"
      :footer="null"
      @cancel="visible = false"
    >
      <div style="font-weight: bold; margin-bottom: 8px; font-size: 14px">
        新版网站：
        <a href="https://ai.njszy.com" target="_blank">https://ai.njszy.com</a>
      </div>
      <div style="font-weight: bold; margin-bottom: 8px; font-size: 14px">
        更多模型：文心一言，科大讯飞星火大模型，通义千问。
      </div>
      <div style="font-weight: bold; margin-bottom: 8px; font-size: 14px">
        更多功能：聚合搜索。
      </div>
      <div style="font-weight: bold; margin-bottom: 8px; font-size: 14px">
        更多平台：Android 端，Windows 端，微信公众号。
      </div>
      <div style="font-weight: bold; margin-bottom: 8px; font-size: 14px">
        使用本系统手机号密码登录即可。
      </div>
    </a-modal>
  </div>
</template>

<script>
import DefaultAvatar from "@/components/default-avatar";
import { mapState } from "vuex";
import MarkdownIt from "markdown-it";
import moment from "moment";

import request from "@/api/request";

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

// function fetchHistory(data) {
//   return request({
//     url: "/office-service/chat/history/list",
//     method: "post",
//     data
//   });
// }

function addHistory(data) {
  return request({
    url: "/office-service/chat/history/add",
    method: "post",
    data,
  });
}

export default {
  components: {
    DefaultAvatar,
  },

  data() {
    return {
      list: [],
      content: "",
      sendLoading: false,

      visible: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },

  // mounted() {
  //   fetchHistory({
  //     robotId: "1143592058834980864",
  //     userId: this.user.uuid
  //   }).then(res => {
  //     console.log("res", res);
  //     if (res && Array.isArray(res.list)) {
  //       const arr = [];
  //       const list = [...res.list].reverse();
  //       list.forEach(item => {
  //         arr.push(
  //           {
  //             id: item.id,
  //             role: "user",
  //             name: this.user.userName,
  //             avatar: this.user.header,
  //             content: item.question
  //           },
  //           {
  //             id: item.id + "-1",
  //             role: "assistant",
  //             name: "GPT-4 Turbo",
  //             avatar: "/openai.png",
  //             content: item.content
  //           }
  //         );
  //       });
  //       this.list = arr;
  //     }
  //   });
  // },

  methods: {
    renderContent(str) {
      return md.render(str);
    },
    send() {
      if (!this.content) {
        this.$message.error("请输入问题");
        return;
      }

      if (this.content.length > 4800) {
        this.$message.error("问题过长，请减少字符");
        return;
      }

      const content = this.content.trim();
      this.content = "";

      let arr = [];
      if (this.list.length > 4) {
        const length = this.list.length;
        arr.push(this.list[length - 4]);
        arr.push(this.list[length - 3]);
        arr.push(this.list[length - 2]);
        arr.push(this.list[length - 1]);
      } else {
        arr = this.list;
      }

      arr = arr.map((item) => {
        return {
          role: item.role,
          content: item.content,
        };
      });

      const newList = [
        ...this.list,
        {
          id: "user" + Date.now(),
          role: "user",
          name: this.user.userName,
          avatar: this.user.header,
          content,
        },
        {
          id: "assistant" + Date.now(),
          role: "assistant",
          name: "ChatGPT",
          avatar: "/openai.png",
          content: "",
        },
      ];

      this.list = newList;

      this.sendLoading = true;

      const ws = new WebSocket(
        "wss://chatgpt-openai-szwmclljda.ap-northeast-1.fcapp.run/chat"
      );

      ws.onopen = (event) => {
        console.log("on open", event);

        const params = {
          // model: 'gpt-4-1106-preview',
          model: "gpt-3.5-turbo",
          messages: [...arr, { role: "user", content }],
          stream: true,
        };
        ws.send(JSON.stringify(params));
      };

      ws.onmessage = (event) => {
        console.log("on message", event, event.data);

        // document.body.scrollTop = document.body.scrollHeight;

        const lastIndex = this.list.length - 1;
        const lastElement = this.list[lastIndex];

        const result = event.data;

        this.list.splice(lastIndex, 1, {
          ...lastElement,
          content: lastElement.content + result,
        });

        this.$nextTick(() => {
          document.querySelector(".list").scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        });
      };

      ws.onclose = (event) => {
        console.log("on close", event);
        this.sendLoading = false;

        const lastElement2 = this.list[this.list.length - 2] || {};
        const lastElement = this.list[this.list.length - 1] || {};

        if (lastElement.content) {
          addHistory([
            {
              robotId: "1143592058834980864",
              robotName: "翎云-gpt-3.5-turbo",
              chatTime: moment().format("YYYY-MM-DD HH:mm:ss"),
              question: lastElement2.content,
              content: lastElement.content,
            },
          ]);
        }
      };

      ws.onerror = (event) => {
        console.log("on error", event);
        this.sendLoading = false;
      };
    },
  },
};
</script>


<style lang="less" scoped>
.list {
  min-height: 80vh;
  padding: 8px 8px 140px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .item {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .avatar {
      display: block;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    .content {
      border-radius: 4px;
      background-color: #f0f0f0;
      // padding: 8px;
      padding: 1em 1em 0 1em;
      line-height: 2;
      max-width: 40vw;
    }
  }
  .user {
    flex-direction: row-reverse;
    .content {
      background-color: #1890ff;
      color: #fff;
    }
  }
}

.footer {
  position: fixed;
  bottom: 16px;
  left: 216px;
  right: 32px;

  .send {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}
</style>